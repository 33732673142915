export default [
  "Windy",
  "Temple",
  "La Lacteo",
  "DDL",
  "Don Abdon",
  "Diafana",
  "Cremosur",
  "La Cabaña",
  "Punta del Agua",
  "La Tirolesa",
  "Grassetto",
  "Plenty",
  "Frigorifico Recreo",
  "Frigorifico Paladini",
  "Generico",
  "Cluselat",
];

export const tipoCliente = [
  "HOME",
  "CORPORATIVO"
]

export const horasTrabajo = [
  "Normal",
  "Media Jornada",
  "Una Jornada"
]

export const passKey = "passname";

export const turnos = [
  {
    value: "turno1",
    name: "Turno 1 (19:30 - 22:30hs)",
  },
  {
    value: "turno2",
    name: "Turno 2 (22:30 - 03:00hs)",
  },
];

export const canchas = [
  {
    value: 1,
    name: "Cancha 1",
  },
  {
    value: 2,
    name: "Cancha 2",
  },
  {
    value: 3,
    name: "Cancha 3",
  },
  {
    value: 0,
    name: "Cancha 0 (socios)",
  },
]

export const mesasArray = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
];

export const dias = [
  {
    value: 1,
    name: "Lunes",
  },
  {
    value: 2,
    name: "Martes",
  },
  {
    value: 3,
    name: "Miércoles",
  },
  {
    value: 4,
    name: "Jueves",
  },
  {
    value: 5,
    name: "Viernes",
  },
  {
    value: 6,
    name: "Sábado",
  },
  {
    value: 0,
    name: "Domingo",
  },
];
